export const SET_CORE_MERCHANT_VALUE = "SET_CORE_MERCHANT_VALUE";

export const SET_IMAGE_URLS = "SET_IMAGE_URLS";

export const SET_ORDER_ID = "SET_ORDER_ID";

export const SET_PROMOTIONAL_BTNS = "SET_PROMOTIONAL_BTNS";

export const setCoreMerchantValue = (value) => ({
  type: SET_CORE_MERCHANT_VALUE,
  payload: value,
});

export const setImageUrls = (backgroundLogo, logoURL) => ({
  type: SET_IMAGE_URLS,
  payload: { backgroundLogo, logoURL },
});

export const setPromotionalBtns = (promotionalBtns) => ({
  type: SET_PROMOTIONAL_BTNS,
  payload: { promotionalBtns },
});

export const setOrderId = (value) => ({
  type: SET_ORDER_ID,
  payload: value,
});
