import { MDBCard, MDBCol, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Check from "../../assets/home/check-bottom.png";
import DownloadWhite from "../../assets/home/download-white.svg";
import Download from "../../assets/home/download.svg";
import EmailWhite from "../../assets/home/email-white.svg";
import Email from "../../assets/home/email.svg";
import ReceiptWhite from "../../assets/home/receipt-white.svg";
import Receipt from "../../assets/home/receipt.svg";
import ShareWhite from "../../assets/home/share-white.svg";
import { default as Share } from "../../assets/home/share.svg";
import i18n from "../../i18n-config/i18n";
import {
  DONWLOAD_INVOICE,
  GET_API_URL,
  LOGIN_GUEST,
  LOGINCONFIG,
} from "../../APIs/index";
import Loader from "./Loader.js";
import { setTokens } from "../../Redux/Actions/authActions";
import {
  setCoreMerchantValue,
  setImageUrls,
  setOrderId,
  setPromotionalBtns,
} from "../../Redux/Actions/mediaDirectoryActions";
import axiosInstance from "../../APIs/axios";
import Axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

class SuccessPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: window.innerWidth <= 435,
      payBill: false,
      recordGuid: urlParams.get("paymentId") ? urlParams.get("paymentId") : "",
      loading: true,
      getMyReceipt: false,
      isHovered: false,
      isHoveredReceipt: false,
      isHoveredDownload: false,
      isHoveredEmail: false,
      token: "",
      showLoader: false,
      underDevelopmentModal: false,
    };
    // this.inputRef = React.createRef();
  }
  handleTabletResize = () => {
    this.setState({ isTablet: window.innerWidth <= 875 });
  };
  handleMobileResize = () => {
    this.setState({ isMobile: window.innerWidth <= 426 });
  };
  componentDidMount() {
    this.updateIsMobileState(); // Initial detection
    window.addEventListener("resize", this.handleResize); // Add event listener

    this.tabletMedia = window.innerWidth <= 768;
    this.mobileMedia = window.innerWidth <= 426;
    this.handleTabletResize();
    this.handleMobileResize();
    window.addEventListener("resize", this.handleTabletResize);
    window.addEventListener("resize", this.handleMobileResize);
    this.fetchVisitorId();
  }

  fetchVisitorId = async () => {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId + "v_1";
      this.setState({ visitorId: visitorId });
      this.loginGuest();
    } catch (error) {
      // console.error('Error fetching visitor ID:', error);
    }
  };

  loginGuest = async () => {
    this.setState({ loading: true, loadingHasOpenInvoice: true });

    try {
      const res = await Axios.post(
        `${LOGIN_GUEST}`,
        {
          Identifier: this.state.visitorId,
        },
        {
          ...LOGINCONFIG,
          headers: {
            ...LOGINCONFIG.headers,
            tenant: `${process.env.REACT_APP_TENANT}`,
            Client: "backend-services",
          },
        }
      );
      const { data } = res.data;
      this.props.setTokens(data?.accessToken, data?.refreshToken);
      this.setState({
        token: data.accessToken,
        loadingHasOpenInvoice: false,
      });

      if (data.accessToken) {
      }
    } catch (err) {
      if (err === "error: 401") {
        this.loginGuest();
      } else if (err?.response?.data?.message === "Login guest error") {
        this.setState({ unavailableSystem: true });
      } else if (err?.response === undefined) {
        this.setState({ wrongBranchModal: true, errors: err?.response });
      } else {
        this.setState({ errors: err?.response?.data?.message });
      }
    } finally {
      this.setState({
        loading: false,
        loadingHasOpenInvoice: false,
        errors: "",
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleTabletResize);
    window.removeEventListener("resize", this.handleMobileResize);
  }

  handleResize = () => {
    this.updateIsMobileState(); // Call method to update isMobile state
  };

  updateIsMobileState = () => {
    const isMobile = window.innerWidth <= 435; // Adjust the width breakpoint as needed
    this.setState({ isMobile });
  };

  toggleGetMyReceiptModal = () => {
    // this.setState({ underDevelopmentModal: true });
    this.setState({ getMyReceipt: !this.state.getMyReceipt });
  };

  handleShare = () => {
    // this.setState({ underDevelopmentModal: true });
    const { recordGuid } = this.state;
    if (navigator.share) {
      navigator
        .share({
          title: "invoice",
          text: "invoice",
          url: `${GET_API_URL}?PaymentGuid=${recordGuid}`,
        })
        .then(() => console.log("Shared successfully."))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API not supported.");
    }
  };

  handleDownload = async () => {
    const { recordGuid, token } = this.state;

    // Prepare request configuration
    const LOGINCONFIG = {
      headers: {
        Authorization: `Bearer ${token}`,
        LanguageCode: i18n.language || "en",
      },
      responseType: "blob", // Important to handle binary data (PDF)
    };

    try {
      // Call API to download the invoice
      const response = await axiosInstance.get(
        `${DONWLOAD_INVOICE}?PaymentGuid=${recordGuid}`,
        LOGINCONFIG
      );
      this.setState({ showLoader: true });

      if (response?.data) {
        this.setState({
          loading: false,
          disabled: false,
          exportLoading: false,
          showLoader: false,
        });

        // Assuming the response contains PDF data, update the content type and file extension
        const contentType = "application/pdf";
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Invoice.pdf"; // Name your PDF file
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.toggleGetMyReceiptModal();
      }
    } catch (error) {
      console.error("Download error", error);
      this.setState({
        loading: false,
        disabled: false,
        exportLoading: false,
        showLoader: false,
      });

      if (error.response?.status === 400) {
        this.setState({
          message: error.response?.data?.message,
          payingTheBillModal: false,
        });
      } else if (error.response?.status === 401) {
        this.hasOpenInvoice(token);
      }
    } finally {
      this.setState({ exportLoading: false });
    }
  };

  handleMouseEnter = (value) => {
    if (value === "share") {
      this.setState({ isHovered: true });
    }
    if (value === "receipt") {
      this.setState({ isHoveredReceipt: true });
    }
    if (value === "download") {
      this.setState({ isHoveredDownload: true });
    }
    if (value === "email") {
      this.setState({ isHoveredEmail: true });
    }
  };

  handleMouseLeave = (value) => {
    if (value === "share") {
      this.setState({ isHovered: false });
    }
    if (value === "receipt") {
      this.setState({ isHoveredReceipt: false });
    }
    if (value === "download") {
      this.setState({ isHoveredDownload: false });
    }
    if (value === "email") {
      this.setState({ isHoveredEmail: false });
    }
  };

  toggleUnderDevelopment = () => {
    this.setState({ underDevelopmentModal: false });
  };

  render() {
    const {
      isHovered,
      isHoveredReceipt,
      isHoveredEmail,
      isHoveredDownload,
      showLoader,
    } = this.state;
    const { t, backgroundLogo, logoURL, promotionalBtns } = this.props;
    return (
      <>
        <>
          <section id="homepage">
            <section id="home" className="section">
              {/* <MDBContainer className="container-img"> */}
              {/* <MDBCard > */}
              <img
                src={backgroundLogo}
                width="400px"
                height="250px"
                className={`${
                  i18n.language === "en"
                    ? "smiley-bg align-items-end"
                    : "smiley-bg align-items-start"
                }`}
                alt="background logo"
              ></img>

              <MDBRow center>
                <MDBCard className="logo-card">
                  <img
                    src={logoURL}
                    width="178px"
                    height="178px"
                    className="taste-img"
                    alt="branch logo"
                  />
                </MDBCard>
              </MDBRow>

              <>
                <MDBRow
                  center
                  className={`margin ${
                    i18n.language === "en"
                      ? " left-to-right "
                      : " right-to-left "
                  }`}
                >
                  <span className="payment-completed mt-3">
                    {localStorage.getItem("paymentCompleted") === true &&
                    localStorage.getItem("paymentCompleted") !== null
                      ? t("Bill Is Already Paid!")
                      : t("Payment Completed!")}
                  </span>
                </MDBRow>
                {localStorage.getItem("paymentCompleted") === true &&
                localStorage.getItem("paymentCompleted") !== null ? (
                  <>
                    <MDBRow
                      center
                      className={`mt-3 ${
                        i18n.language === "en"
                          ? " left-to-righ "
                          : " right-to-left "
                      }`}
                    >
                      <span className="success-desc">
                        {" "}
                        {t("Your table mates")}
                      </span>
                    </MDBRow>
                    <MDBRow
                      center
                      className={`${
                        i18n.language === "en"
                          ? " left-to-right "
                          : " right-to-left"
                      }`}
                    >
                      <span className="success-desc">
                        {" "}
                        {t("have paid the bill")}
                      </span>
                    </MDBRow>
                  </>
                ) : (
                  <>
                    <MDBRow
                      center
                      className={`mt-3 ${
                        i18n.language === "en"
                          ? " left-to-righ "
                          : " right-to-left "
                      }`}
                    >
                      <span className="success-desc"> {t("Thank you")}</span>
                    </MDBRow>
                    <MDBRow
                      center
                      className={`${
                        i18n.language === "en"
                          ? " left-to-right "
                          : " right-to-left"
                      }`}
                    >
                      <span className="success-desc">
                        {" "}
                        {t("See you next time!")}
                      </span>
                    </MDBRow>
                  </>
                )}
                {localStorage.getItem("paymentCompleted") === true ? (
                  <MDBRow className="mt-3 mb-3" center>
                    <MDBCard
                      onMouseEnter={() => this.handleMouseEnter("receipt")}
                      onMouseLeave={() => this.handleMouseLeave("receipt")}
                      onClick={this.toggleGetMyReceiptModal}
                      className={`card-completed cursor-pointer ${
                        i18n.language === "en" ? " ml-1" : " mr-1 left-to-right"
                      }`}
                    >
                      <MDBRow className="d-flex justify-content-center">
                        <span className="share ml-1">
                          <img
                            src={isHoveredReceipt ? ReceiptWhite : Receipt}
                            alt="Receipt Icon"
                            className="mr-2"
                            width="19px"
                            height="20px"
                          />
                          {t("Get My Receipt")}
                        </span>
                      </MDBRow>
                    </MDBCard>
                  </MDBRow>
                ) : (
                  <>
                    <MDBRow className="mt-5" center>
                      <MDBCard
                        onMouseEnter={() => this.handleMouseEnter("share")}
                        onMouseLeave={() => this.handleMouseLeave("share")}
                        className={`card-completed cursor-pointer ${
                          i18n.language === "en"
                            ? "  ml-1"
                            : " mr-1 left-to-right"
                        }`}
                      >
                        <MDBRow
                          onClick={this.handleShare}
                          className="d-flex justify-content-center"
                        >
                          <span className="share ml-1">
                            <img
                              src={isHovered ? ShareWhite : Share}
                              alt="Share Icon"
                              className="mr-2"
                              width="19px"
                              height="20px"
                            />
                            {t("Share The Bill")}
                          </span>
                        </MDBRow>
                      </MDBCard>
                    </MDBRow>

                    <MDBRow className="mt-3 mb-3" center>
                      <MDBCard
                        onMouseEnter={() => this.handleMouseEnter("receipt")}
                        onMouseLeave={() => this.handleMouseLeave("receipt")}
                        onClick={this.toggleGetMyReceiptModal}
                        className={`card-completed cursor-pointer ${
                          i18n.language === "en"
                            ? " ml-1"
                            : " mr-1 left-to-right"
                        }`}
                      >
                        <MDBRow className="d-flex justify-content-center">
                          <span className="share ml-1">
                            <img
                              src={isHoveredReceipt ? ReceiptWhite : Receipt}
                              alt="Receipt Icon"
                              className="mr-2"
                              width="19px"
                              height="20px"
                            />
                            {t("Get My Receipt")}
                          </span>
                        </MDBRow>
                      </MDBCard>
                    </MDBRow>
                  </>
                )}

                <MDBRow center>
                  <MDBCard className="imgs-card mr-3 ml-3">
                    <MDBRow center>
                      {promotionalBtns?.details?.length > 0
                        ? promotionalBtns?.details?.map((item, index) => (
                            <MDBCol size="4" key={index}>
                              <MDBRow center>
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Promotional Button"
                                    src={item?.iconIdentifier}
                                    className="logo-img"
                                    height="50"
                                  />
                                </a>
                              </MDBRow>
                              <MDBRow center>
                                <p className="titles">{t(item?.title)}</p>
                              </MDBRow>
                            </MDBCol>
                          ))
                        : []}
                    </MDBRow>
                  </MDBCard>
                </MDBRow>

                <MDBRow className="success-inner-row mt-5" center>
                  <MDBCol size="12" className="qcheck-logo-col">
                    <img
                      src={Check}
                      className="qcheck-logo"
                      alt="qcheck-logo"
                    />
                  </MDBCol>
                </MDBRow>
              </>
              {/* </MDBContainer> */}
            </section>
          </section>
        </>

        <MDBModal
          isOpen={this.state.getMyReceipt}
          toggle={this.toggleGetMyReceiptModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow className="mt-2">
              <MDBCol md="12">
                <p className="modal-title text-initial">
                  {t("Get My Receipt")}
                </p>
              </MDBCol>
              <MDBCol>
                <p className="modal-text mb-0 text-initial">
                  {t("Select the method")}
                </p>
                <p className="modal-text text-initial">
                  {t("that fits you best!")}
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2" center>
              <MDBCard
                onMouseEnter={() => this.handleMouseEnter("download")}
                onMouseLeave={() => this.handleMouseLeave("download")}
                className={`card-completed cursor-pointer ${
                  i18n.language === "en" ? "  ml-1" : "mr-1 left-to-right"
                }`}
              >
                <MDBRow
                  onClick={() => this.handleDownload()}
                  className="d-flex"
                >
                  <span className="btn-modal ml-1 text-initial">
                    {showLoader ? (
                      <Loader />
                    ) : (
                      <>
                        {" "}
                        <img
                          src={isHoveredDownload ? DownloadWhite : Download}
                          alt="Download Icon"
                          className="mr-2"
                          width="19px"
                          height="20px"
                        />
                        {t("Download")}
                      </>
                    )}
                  </span>
                </MDBRow>
              </MDBCard>
            </MDBRow>
            <MDBRow className="my-4" center>
              <MDBCard
                onMouseEnter={() => this.handleMouseEnter("email")}
                onMouseLeave={() => this.handleMouseLeave("email")}
                className={`cursor-pointer card-completed  ${
                  i18n.language === "en" ? "  ml-1" : " mr-1 left-to-right"
                }`}
              >
                <MDBRow onClick={this.handleShare} className="d-flex">
                  <span className="btn-modal ml-1 t5ext-initial">
                    <img
                      src={isHoveredEmail ? EmailWhite : Email}
                      alt="Email Icon"
                      className="mr-2"
                      width="19px"
                      height="20px"
                    />
                    {t("Send By Email")}
                  </span>
                </MDBRow>
              </MDBCard>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.underDevelopmentModal}
          toggle={this.toggleUnderDevelopment}
          size="md"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow className="mt-2">
              <MDBCol>
                <p className="modal-text mb-0 text-initial">
                  {t("This feature will be available soon.")}
                </p>
                <p className="modal-text mb-0 text-initial">
                  {t(" Thank you for your patience!")}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication ? state.authentication.token : "",
  coreMerchantValue: state?.mediaDirectory
    ? state?.mediaDirectory?.coreMerchantValue
    : "",
  accessToken: state?.auth ? state?.auth?.accessToken : "",
  refreshToken: state?.auth ? state?.auth?.refreshToken : "",
  backgroundLogo: state?.mediaDirectory
    ? state?.mediaDirectory?.backgroundLogo
    : "",
  logoURL: state?.mediaDirectory ? state?.mediaDirectory?.logoURL : "",
  orderId: state?.mediaDirectory ? state?.mediaDirectory?.orderId : "",
  promotionalBtns: state?.mediaDirectory
    ? state?.mediaDirectory?.promotionalBtns
    : "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTokens: (aceessToken, refreshToken) =>
    dispatch(setTokens(aceessToken, refreshToken)),
  setCoreMerchantValue: (coreMerchantValue) =>
    dispatch(setCoreMerchantValue(coreMerchantValue)),
  setImageUrls: (backgroundLogo, logoURL) =>
    dispatch(setImageUrls(backgroundLogo, logoURL)),
  setOrderId: (orderId) => dispatch(setOrderId(orderId)),
  setPromotionalBtns: (promotionalBtns) =>
    dispatch(setPromotionalBtns(promotionalBtns)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("translations")(SuccessPage))
);
