import FailedPage from "../Containers/Home/FailedPage";
import HomePage from "../Containers/Home/HomePage";
import SuccessPage from "../Containers/Home/SuccessPage";

export const routesWithoutAuthValidation = [
  {
    path: "/",
    component: HomePage,
    key: "/",
    exact: true,
  },
  {
    path: "/failed",
    component: FailedPage,
    key: "/failed",
    exact: true,
  },
  {
    path: "/success",
    component: SuccessPage,
    key: "/success",
    exact: true,
  },
];
